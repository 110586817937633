<template>
  <div>
    <div class="inline-flex align-center">
      <md-icon>list_alt</md-icon>
      <h3 class="pl-2 text-uppercase">Forms</h3>
    </div>

    <div class="md-layout md-gutter mt-4">
      <div class="md-layout-item md-size-75">
        <div class="outline-gray-400 p-4 rounded-lg">
          <div>
            <h2>Contact Form</h2>
            <p class="text-italic opacity-75 text-base mt-2 mb-4">
              A short description whould go there
            </p>
            <md-divider></md-divider>
          </div>

          <div class="md-layout md-gutter mt-6">
            <div class="md-layout-item md-layout md-size-75">
              <div class="md-layout-item mb-6 mr-4">
                <InputFieldComponent label="First Name" italic />
              </div>
              <div class="md-layout-item mb-6 ml-4">
                <InputFieldComponent label="Last Name" italic />
              </div>

              <div class="md-layout-item md-size-100 mb-6">
                <InputFieldComponent label="Email Address" italic />
              </div>

              <div class="md-layout-item md-size-100 mb-6">
                <label
                  for=""
                  class="mb-1 pr-4 block text-uppercase font-bold text-italic"
                  >Message</label
                >
                <textarea
                  class="w-full outline-gray-400"
                  name=""
                  id=""
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
            </div>
          </div>
          <div class="md-layout md-gutter align-center">
            <div class="md-layout-item ">
              <InputFieldComponent label="Number" italic />
            </div>
            <div class="md-layout-item md-size-25">
              <md-icon class="mr-2 text-primary">pan_tool</md-icon>
              <md-icon class="mr-2 text-green">edit</md-icon>
              <md-icon class="mr-2 text-danger">clear</md-icon>
            </div>
            <div class="md-layout-item md-size-100 mt-10 flex justify-end">
                <md-button class="bg-default text-white rounded">Save As Draft</md-button>
                <md-button class="bg-victoria text-white rounded">Publish</md-button>
            </div>
          </div>
        </div>
      </div>
      <div class="md-layout-item">
        <InputItemCollapse />
      </div>
    </div>
  </div>
</template>

<script>
import { InputFieldComponent } from "@/components";
import InputItemCollapse from "@/components/molecule/form/InputItemCollapse";
import data from "@/data/form/data";

export default {
  name: "Form-List-View",
  components: {
    InputItemCollapse,
    InputFieldComponent
  },
  data() {
    return {
      forms: data.forms,
    };
  }
};
</script>
