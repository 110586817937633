
const forms = [];
const inputs = [];

for(let i = 1; i <= 15; i++) {
    forms.push({
        id: i,
        formId: 'FT251'+i,
        blogIndex: 'Contact Form',
        createdAt: '23 Sep, 2020, 9:00am',
        status: 'Active'
    });
}

var labels = ['Standard Fields', 'Advanced Fields', 'Post Fields', 'Pricing Fields'];

var icons = ['format_textdirection_l_to_r', 'unfold_more', 'list', 'visibility_off', 'email', 'date_range', 'schedule', 'assignment_ind', 'help', 'public'];

var names = ['Paragraph Text', 'Dropdown', 'List', 'Hidden', 'Email', 'Calendar', 'Time', 'Username', 'Quiz', 'Website'];


for (let j = 0; j < 4; j++) {
    inputs.push({
        id: j,
        label: labels[j],
    });
    inputs[j].children = [];
    for (let k = 0; k < names.length; k++) {
        let index = Math.floor(Math.random() * 9);
        inputs[j].children.push({
            id: k,
            icon: icons[index],
            label: names[index]
        })
    }
}

export default {
    forms,
    inputs
}