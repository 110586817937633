<template>
  <div>
      <div class="outline-gray-400 rounded mb-3" v-for="(input, index) in inputs" :key="input.id">
          <div class="flex justify-between align-center bg-victoria p-1 pointer" @click="onSelectedCollapse(index)">
              <p class="text-base pl-2 text-white">{{ input.label }}</p>
              <md-icon class="m-0 text-white" v-if="index === current && isOpen">expand_less</md-icon>
              <md-icon class="m-0 text-white" v-else>expand_more</md-icon>
              <!-- expand_less -->
          </div>
          <div class="md-layout" v-if="index === current && isOpen">
              <div class="md-layout-item md-size-50" v-for="item in input.children" :key="item.id">
                  <div class="flex align-center bg-primary p-1 text-white rounded m-1 pointer">
                      <md-icon class="m-0 text-white mr-3">{{ item.icon }}</md-icon>
                      <p>{{ item.label }}</p>
                  </div>
              </div>
          </div>
      </div>
  </div>
</template>

<script>
import data from '@/data/form/data'
export default {
    data() {
        return {
            inputs: data.inputs,
            current: 0,
            isOpen: true,
        }
    },
    methods: {
        onSelectedCollapse(index){
            if(this.current == index) {
                this.isOpen = !this.isOpen;
            } else {
                this.isOpen = true;
            }

            this.current = Number(index);
        },
    }
}
</script>

<style>

</style>